var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.table)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaderRecords,"items":_vm.records,"items-per-page":5,"group-by":"companyName","show-group-by":false,"footer-props":{
            itemsPerPageOptions: [5, 10, 25, 50, -1],
            itemsPerPageText: _vm.$t('perPage'),
            itemsPerPageAllText: _vm.$t('all'),
            pageText: '{0}-{1} ' + _vm.$t('from') + ' {2}'
        }},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
        var group = ref.group;
        var headers = ref.headers;
return [_c('td',{staticClass:"text-start",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(group)+" ")])]}}],null,true)}):_vm._e(),(!_vm.table)?_c('v-list',{staticClass:"text-left",attrs:{"dense":"","disabled":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.records),function(record,i){return _c('v-list-item',{key:i,attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(record.internalId)+" | "+_vm._s(record.name))])],1)],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }