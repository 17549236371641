<template>
<div>
     <v-data-table
        v-if="table"
        :headers="tableHeaderRecords"
        :items="records"
        :items-per-page="5"
        class="elevation-1"
        group-by="companyName"
        :show-group-by="false"
        :footer-props="{
            itemsPerPageOptions: [5, 10, 25, 50, -1],
            itemsPerPageText: $t('perPage'),
            itemsPerPageAllText: $t('all'),
            pageText: '{0}-{1} ' + $t('from') + ' {2}'
        }">
        <template v-slot:[`group.header`]="{ group, headers }">
            <td :colspan="headers.length" class="text-start">
                {{ group }}
            </td>
        </template>
    </v-data-table>
    <v-list v-if="!table" dense disabled class="text-left">
        <v-list-item-group color="primary">
        <v-list-item
            v-for="(record, i) in records"
            :key="i"
            dense
        >
            <v-list-item-content>
            <v-list-item-title>{{ record.internalId }} | {{ record.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
    </v-list>
</div>
</template>

<script>
export default {
    props: {
        table: {
            type: Boolean,
            default: true
        },
        records: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            tableHeaderRecords: [
                { text: 'ID', align: 'start', value: 'internalId' },
                { text: this.$t('name'), value: 'name' },
            ]
        }
    }
}
</script>